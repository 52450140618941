.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:baseline;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-top: 5%;
    width: 100%;
    height: auto;
    margin-bottom: 0;
    padding-bottom: 5%;
  }

  .highlight{
    color: teal;
    font-size: calc(20px + 2vmin);
  }

  .circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .circle-image {
    display: block;
    width: 100%;
    height: auto;
  }

  .header-wrapper{
    padding: 5%;
  }