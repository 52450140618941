#resumeSection{
    height: auto;
    color: white;
    display: flex;
    justify-content: center;
}

.download-button {
    width: 200px;
    justify-content: center;
  }

  .skillBox{
    height: auto;
    margin: 10px;
  }